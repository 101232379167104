export default {
  error_occurred: 'Desculpe, ocorreu um erro. Por favor, tente novamente em alguns instantes.',
  new_content_available: "Há uma nova atualização de página, clique em 'Atualizar' para recarregar a página.",
  refresh_button_title: 'Atualizar',
  force_reload_message: 'Desculpe, ocorreu um erro. Nossa equipe já está trabalhando nisso. Por favor, tente recarregar o site em um momento.',
  sign_up: 'Crie a sua conta aqui',
  service: 'Serviço',
  employee: 'Empregado',
  select_employee: 'Selecione uma pessoa',
  employees: 'Funcionários',
  services: 'Serviços',
  duration: 'Duração',
  from: 'A partir de',
  select_date: 'Selecione uma data',
  no_account_question: 'Não tem uma conta ainda?',
  name: 'Primeiro nome',
  surname: 'Sobrenome',
  name_and_surname: 'Nome completo',
  phone: 'Telefone',
  password: 'Senha',
  field_is_required: 'Este campo é obrigatório.',
  value_is_too_long: 'O valor é muito longo.',
  value_is_too_short: 'O valor é muito curto.',
  signup_was_successful: 'O registro foi realizado com sucesso. Fazendo login no painel.',
  signup_has_failed: 'O registro falhou. Por favor, tente novamente em alguns instantes.',
  email_is_taken: 'A conta com o endereço de e-mail fornecido já existe. Se você esqueceu sua senha, use a opção de lembrete.',
  email_or_password_incorrect: 'O endereço de e-mail ou senha está incorreto. Se você esqueceu sua senha, use a opção de lembrete.',
  account_not_found: 'Conta não encontrada.',
  page_not_found: 'Ops! A página que você estava procurando não foi encontrada :(',
  go_back_to_main_page: 'Volte para a página inicial e tente novamente.',
  bookings: 'Reservas',
  account: 'Minha conta',
  date: 'Encontro',
  cancel: 'Cancelar',
  date_from: 'Data de',
  date_to: 'Data para',
  nothing_found_here: 'Nada foi encontrado.',
  old_password: 'Senha Antiga',
  remove: 'Excluir',
  phone_is_invalid: 'O número de telefone está incorreto.',
  book: 'Livro',
  sign_up_2: 'Crie a sua conta aqui',
  log_in: 'Entrar',
  your_email_address: 'Seu endereço de email',
  select: 'Selecionar',
  filters: 'Filtros',
  expand: 'Expandir',
  collapse: 'Colapso',
  bookings_drawer_title: 'Selecione filtros para mostrar apenas as reservas que você está procurando.',
  clear_filters: 'Redefinir filtros',
  show_more: 'Mostre mais',
  dont_you_remember_password: 'Esqueceu sua senha?',
  password_information: 'A senha deve ter pelo menos 8 caracteres, conter uma letra maiúscula, um número e um caractere especial.',
  forgotten_password_button: 'Envie um link para alterar sua senha',
  do_you_already_have_an_account: 'Você já tem uma conta?',
  reset_password_title: 'Definir nova senha',
  reset_password_subtitle: 'Digite sua nova senha abaixo.',
  reset_password_button: 'Salve a nova senha',
  profile: 'Perfil',
  logout: 'Sair',
  cancel_booking: 'Cancelar sua reserva',
  status_approved: 'Aprovado',
  status_payment: 'Aguardando pagamento',
  status_done: 'Concluído',
  status_cancelled: 'Cancelado',
  cancel_booking_dialog_title: 'Deseja cancelar sua reserva?',
  cancel_booking_dialog_information: 'Essa ação não pode ser desfeita!',
  cancel_booking_dialog_button: 'Sim, cancelar minha reserva',
  my_profile: 'Meu perfil',
  my_data: 'Meus detalhes',
  upload_new_photo: 'Adicionar uma nova foto',
  birthday: 'Aniversário',
  save_changes: 'Salvar alterações',
  phone_number: 'Número de telefone',
  directional: 'Código de área',
  save_new_password: 'Salve a nova senha',
  new_password: 'Nova Senha',
  password_change: 'Mudar senha',
  view: 'Visão',
  services_2: 'Serviços',
  accept_terms_and_conditions_1: 'Ao clicar na opção abaixo, concordo com os',
  accept_terms_and_conditions_2: 'Termos e Condições',
  accept_terms_and_conditions_3: 'e confirmo que li o',
  accept_terms_and_conditions_4: 'Declaração de privacidade.',
  to: 'Para',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Entrar',
  email_is_incorrect: 'O endereço de e-mail está incorreto.',
  logged_out: 'Voce foi desconectado',
  booking_dialog_date_is_already_taken: 'A hora selecionada não está disponível. Você se importaria de escolher um lugar diferente?',
  data_search: 'Procurando dados. Por favor, espere...',
  no_employees: 'Sem funcionários',
  no_services: 'Sem serviços',
  no_categories: 'Nenhuma categoria',
  no_bookings: 'Reservas não encontradas.',
  no_subscriptions: 'Nenhuma assinatura foi encontrada.',
  female: 'Fêmea',
  male: 'Macho',
  other: 'Outro',
  no_numeric_in_password: 'Nenhum caractere numérico na senha.',
  no_lowercase_in_password: 'Nenhuma letra minúscula na senha.',
  no_uppercase_in_password: 'Nenhuma letra maiúscula na senha.',
  no_special_character: 'Nenhum caractere especial.',
  min_n_characters: 'O número mínimo de caracteres é @{min}.',
  max_n_characters: 'O número máximo de caracteres é @{max}.',
  email: 'E-mail',
  reset_password_fail_text: 'A senha não foi alterada. O link provavelmente expirou.',
  reset_password_success_title: 'Sua senha foi mudada.',
  reset_password_success_text: 'Você pode entrar com sua nova senha.',
  account_activation: 'Ativação de conta',
  account_activation_success: 'A conta foi ativada.',
  account_activation_fail: 'Ocorreu um problema ao ativar sua conta. Parece que a conta já está ativa ou o link não é mais válido.',
  account_activation_processing: 'Ativando sua conta. Por favor, espere...',
  account_activation_resend_title: 'A conta não foi ativada',
  account_activation_resend_text: 'Ative sua conta clicando no link de ativação no e-mail que lhe enviamos.',
  account_activation_resend_button: 'Envie novamente',
  confirm_send_activate_link_dialog_subtitle: 'Enviamos um e-mail com o link de ativação para o endereço de e-mail fornecido anteriormente.',
  file_is_too_large: 'O arquivo selecionado é muito grande.',
  appointment_title: 'Selecione uma pessoa, data e hora da reserva',
  others: 'Outro',
  countryLabel: 'País',
  requiredMessages: 'É necessário um número de telefone',
  date_of_visit: 'Encontro',
  hour_of_visit: 'Tempo',
  thank_you_for_booking: 'Sua reserva',
  no_free_hours: 'Não há horários disponíveis no dia selecionado.',
  any_person: 'Escolha aleatória',
  password_changed: 'Sua senha foi mudada',
  incorrect_old_password: 'A senha antiga está incorreta',
  error404: 'Erro 404',
  minutes: 'min',
  hours: 'h',
  days: 'd',
  ok: 'OK',
  web_page_temporary_disabled: 'Desculpe! Esta página foi temporariamente desativada.',
  profile_update_success: 'Seus dados foram salvos.',
  form_is_invalid: 'O formulário contém erros. Verifique os campos destacados e tente novamente.',
  search: 'Procurar',
  pay_online_button_title: 'Pague on-line',
  account_not_activated_info: 'O endereço de e-mail não foi confirmado. Confirme seu endereço de e-mail clicando no link na mensagem enviada.',
  paid: 'Pago',
  payments: 'Pagamentos',
  payment_service: 'Serviço',
  payment_date: 'Encontro',
  payment_price: 'Preço',
  payment_success_title: 'O pagamento foi efetuado com sucesso, obrigado!',
  payment_fail_title: 'O pagamento falhou, tente novamente.',
  pay_again: 'Pague novamente',
  newsletter_subscribe_success: 'Obrigada! Seu endereço de e-mail foi confirmado.',
  newsletter_subscribe_fail: 'Ocorreu um problema ao ativar seu endereço de e-mail. Parece que o endereço de e-mail já está ativo ou o link não é mais válido.',
  newsletter_send_again: 'Envie novamente',
  newsletter_send_again_success: 'O link de ativação foi enviado novamente.',
  send_again_fail: 'Ocorreu um problema ao gerar um novo link de ativação, talvez seu endereço de e-mail já esteja ativo ou você acabou de gerar o link.',
  newsletter_email_is_used: 'O endereço de e-mail já está na lista, caso o endereço não tenha sido confirmado, podemos reenviar o link de ativação.',
  newsletter_unsubscribe_success: 'Obrigada! Seu endereço de e-mail foi removido.',
  newsletter_unsubscribe_fail: 'Ocorreu um problema ao excluir o endereço de e-mail. Parece que o endereço de e-mail já foi excluído ou o link expirou.',
  booking_payments: 'Reservas',
  simple_store_product_transactions: 'Produtos',
  payment_type_select_box: 'Selecione o tipo de pagamento',
  payment_description: 'Descrição',
  add_to_cart: 'Adicionar ao carrinho',
  remove_from_cart: 'Retire do carrinho',
  save: 'Salvar',
  bookings_cart_summary: 'Sumário',
  today: 'Hoje',
  fill_booking_details_title: 'Insira os detalhes',
  create_an_account_question: 'Crie uma conta e envie a senha para o endereço de e-mail',
  value_is_incorrect: 'Este valor não é válido.',
  first_available_date: 'Horários disponíveis em',
  service_location_1: 'Endereço',
  service_location_2: 'Telefone',
  service_location_3: 'Google Meet',
  service_location_4: 'Outro',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Localização',
  booking_not_found: 'Reserva não encontrada.',
  booking_canceled: 'A reserva foi cancelada.',
  booking_canceled_fail: 'Esta reserva não pode ser cancelada.',
  day: 'Dia',
  week: 'Semana',
  month: 'Mês',
  year: 'Ano',
  booking_plural_1: 'Reserva',
  booking_plural_2: 'Reservas',
  booking_plural_3: 'Reservas',
  booking_plural_4: 'Reservas',
  booking_plural_5: 'Reservas',
  day_plural_1: 'Dia',
  day_plural_2: 'Dias',
  day_plural_3: 'Dias',
  day_plural_4: 'Dias',
  day_plural_5: 'Dias',
  week_plural_1: 'Semana',
  week_plural_2: 'Semanas',
  week_plural_3: 'Semanas',
  week_plural_4: 'Semanas',
  week_plural_5: 'Semanas',
  month_plural_1: 'Mês',
  month_plural_2: 'Meses',
  month_plural_3: 'Meses',
  month_plural_4: 'Meses',
  month_plural_5: 'Meses',
  year_plural_1: 'Ano',
  year_plural_2: 'Anos',
  year_plural_3: 'Anos',
  year_plural_4: 'Anos',
  year_plural_5: 'Anos',
  tax: 'ID do IVA',
  tax_prefix: 'Prefixo',
  get_company_data: 'Procurar',
  get_company_data_failed: 'Os dados não puderam ser baixados.',
  company_name: 'Nome da empresa',
  street_and_number: 'Rua e número',
  postcode: 'Código postal',
  city: 'Cidade',
  country: 'País',
  subscribe: 'Se inscrever',
  recurrent_fee_info: 'Faturado a cada',
  one_time_fee_info: 'Taxa única.',
  new_account_title: 'Criar uma nova conta',
  payment_data_title: 'Detalhes do pagamento',
  billing_data_checkbox_question: 'Detalhes de faturamento',
  confirmation_modal_header: 'A ação requer confirmação',
  confirmation_modal_warning: 'Você tem certeza que quer continuar?',
  no_way_to_undo_action: 'Essa ação não pode ser desfeita!',
  delete_payment_method: 'Remover cartão',
  add_payment_method: 'Adicionar cartão',
  fill_card_details: 'Preencha os dados de pagamento.',
  subscriptions: 'Assinaturas',
  subscription: 'Inscrição',
  invoice_number: 'Fatura',
  number: 'Número',
  status: 'Status',
  details: 'Detalhes',
  created_at: 'Criada',
  subscription_status_initiated: 'Não pago',
  subscription_status_active: 'Ativo',
  subscription_status_inactive: 'Inativo',
  subscription_status_canceled: 'Cancelado',
  ends_at: 'Termina em',
  ends_at_default: 'Válido até o final do período de cobrança',
  select_subscription_to_pay: 'Selecione uma assinatura para pagar.',
  pay_with_subscription: 'Pague com assinatura',
  bookings_paid_by_subscription_success_message: 'Pago',
  bookings_not_paid_by_subscription_error_message: 'A reserva não pôde ser paga com a assinatura selecionada.',
  stripe: 'Listra',
  provider_tag: 'Método',
  card: 'Cartão',
  cash: 'Dinheiro',
  transfer: 'Transferir',
  promo_code_label: 'Código de desconto',
  promo_code_invalid_message: 'O código de desconto fornecido é inválido.',
  payment_method_setup_error: 'Houve um problema com o seu cartão. Seu banco se recusou a autorizar o cartão para transações posteriores. Entre em contato com seu banco.',
  active_subscription_missing: 'A reserva deste serviço requer uma assinatura ativa. Infelizmente, você não tem nenhuma assinatura para usar.',
  area_code: 'Código de área',
  usage: 'Uso',
  create_account_in_stripe_failed: 'O operador de pagamento rejeitou seus dados de assinatura. Por favor, verifique a exatidão do formulário (número de identificação fiscal, número de telefone, endereço de e-mail).',
  additional_auth_required: 'Seu banco requer autorização adicional',
  general_payment_error: 'Ocorreu um problema com sua forma de pagamento. Talvez seu cartão não suporte assinaturas. Entre em contato com seu banco ou escolha uma forma de pagamento diferente.',
  subscription_set_successfully: 'Assinatura configurada corretamente.',
  booking_created: 'Nova reserva',
  booking_updated: 'Mudança de reserva',
  booking_deleted: 'Exclusão de uma reserva',
  booking_reminder: 'Lembrete de reserva',
  email_notifications: 'Notificações por e-mail',
  sms_notifications: 'Notificações SMS',
  save_user_preferences: 'Salvar preferências',
  free_spots: 'Pontos restantes',
  no: 'Não',
  yes: 'Sim',
  p24_inactive: 'O operador de pagamento Przelewy24 não pode ser usado. Por favor, entre em contato com seu operador de pagamento.',
  stripe_customer_invalid: 'ID de cliente Stripe inválida. A forma de pagamento não pode ser anexada ao cliente.',
  stripe_resource_invalid: 'O produto selecionado não está disponível. Por favor, verifique a disponibilidade do produto com o operador de pagamento.',
  account_blocked_message: 'Sua conta está inativa. Entre em contato com o administrador.',
  time_zone: 'Fuso horário',
  no_spots_left: 'Você atingiu o número máximo de vagas disponíveis.',
  employee_auth_required: 'Faça login na sua conta ou use um endereço de e-mail diferente. As contas dos funcionários exigem login.',
  subpage_auth_required: 'Faça login na sua conta para visualizar a guia.',
  gross: 'Valor bruto.',
  subscription_login_info: 'já tem uma conta? Faça login abaixo para preencher seus dados.',
  change_date_time: 'Reprogramar',
  promo_code_checkbox: 'Eu tenho um código de desconto',
  booking_promo_code_label: 'Digite o código de desconto',
  select_hour: 'Escolha o horário',
  id: 'Id',
  booking_rescheduled: 'Sua reserva foi alterada.',
  booking_rescheduled_fail: 'Esta reserva não pode ser alterada.',
  max_user_bookings_error: 'Você excedeu o número máximo de reservas. Escolha outro serviço ou entre em contato conosco.',
  in_total: 'Total',
  company: 'Profissional',
  individual: 'Particular',
  bulk_payment: 'Pagamento em massa',
  make_bulk_payment: 'Pagamento em massa',
  simple_store_product_confirmation_title: 'Preencha o formulário de compra',
  select_service_type: 'Selecione uma opção de serviço',
  add_to_calendar_button_label: 'Incluir no Calendário',
  login_to_use_service_with_subscription: 'Faça login na sua conta para agendar um horário para este serviço.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'A data escolhida não está disponível. Por favor, escolha outra data livre. No caso de reservar várias datas, certifique-se de que as datas não se excluam mutuamente.',
  select_location_calendar_warning: 'Selecione um local para carregar os horários disponíveis.',
  selectable_location_online_title: 'Online',
  change: 'Mudar',
  show_less_hours_button: 'Mostrar menos horas',
  show_more_hours_button: 'Mostrar mais horas',
  add_another_button_title: 'Adicionar outro',
  close: 'Fechar',
  selected_time_slots: 'Intervalos de tempo selecionados',
  select_location: 'Selecione o local',
  captcha_invalid_error_notification: 'A verificação de captcha falhou. Tente recarregar a página se o problema persistir.',
  verify_code_incorrect_error: 'O código inserido está incorreto.',
  verify_code_too_many_requests: 'Atualmente não podemos gerar um novo código. Tente novamente mais tarde ou utilize o código que já enviamos.',
  send_again_button_title: 'Enviar novamente',
  verify_title: 'Verificar',
  verify_code_title: 'Insira o código de verificação',
  verify_code_description: 'Enviamos um código de verificação de 6 dígitos para o seu e-mail. Insira-o abaixo:',
  verification_invalid_error_notification: 'Verificação inválida. Tente novamente mais tarde.'
}
